@use "./variable" as *;

.page-iterator {
  > .content {
    width: 90px;
    display: flex;
    justify-content: space-between;
    > svg {
      color: $liteOrange;
    }
    .activeIterate {
      color: $orangeHeavy;
      cursor: pointer;
    }
    > span {
      color: $blueSea;
      user-select: none;
    }
  }
}
