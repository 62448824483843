@use "./variable" as *;
.auth-page {
  padding-top: $header-height;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-top: $mobile-header-height;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 2px solid $sand;
    min-height: 600px;
    @media (max-width: 768px) {
      min-height: 700px;
    }
    .big-image {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      //   min-width: 200px;
      min-height: 624px;
      background-color: $blueSea;
      > img {
        max-height: 590px;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    .auth-form {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      min-height: 300px;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      padding-top: 5%;
      .wrapper {
        max-width: 460px;
        width: 100%;
        height: 486px;
        // text-align: center;
        // display: flex;
        // justify-content: space-between;
        // flex-direction: column;
      }
      .top {
        text-transform: capitalize;
        text-align: center;
        > h2 {
          color: $blueSea;
          font-size: 30px;
        }
        small {
          color: $rsasii;
        }
      }
      form {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 5%;
        input {
          padding: 10px;
          border: 1px solid $rsasii;
          border-radius: 5px;
          height: 55px;
          outline: none;
          &::placeholder {
            text-transform: capitalize;
          }
        }
        ul {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          column-gap: 10px;
          li {
            width: 100%;
            display: flex;
            flex-direction: column;
            span {
              color: $orangeHeavy;
              font-size: 13px;
              text-transform: capitalize;
            }
            a {
              color: $blueSea;
            }
          }
          .pass-wraper {
            section {
              display: flex;
              flex-direction: row;
              column-gap: 10px;
              width: 100%;
              > input {
                width: 100%;
                height: 55px;
              }
              @media (max-width: 867px) {
                flex-direction: column;
                row-gap: 10px;
              }
            }
          }
          .forgot-pass {
            text-align: right;
            text-transform: capitalize;
          }
          .helpers {
            text-align: center;
            text-transform: capitalize;
          }
          .driver-license {
            text-transform: capitalize;
            color: $blueSea;
          }
        }
        button {
          width: 100%;
          text-align: center;
          border-radius: 10px;
          border: none;
          height: 55px;
          color: $graySilver;
          text-transform: capitalize;
          font-size: 20px;
        }
        .active-btn {
          cursor: pointer;
          color: white;
          background-color: $orangeHeavy;
          &:hover {
            background-color: $sand;
          }
        }
      }
    }
  }
}
