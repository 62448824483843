@use "./variable" as *;

.product-info {
  display: flex;
  flex-direction: column;
  background-color: white;
  // padding: 15px 10px;
  align-items: flex-start;
  height: 100%;
  // width: 240px;
  width: 200px;
  border-radius: 10px;
  border: 1px solid $silverMid;

  @media (max-width: 768px) {
    // width: 90%;
    width: 155px;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex: 1;
    justify-content: space-between;
    // width: 100%;

    section {
      padding: 10px;
      width: 100%;
      border-bottom: 1px solid $silverMid;

      h3 {
        font-weight: normal;
        width: 100%;
        overflow: hidden;
        color: $blueSea;
        height: 35px;
        // display: flex;
        // align-items: center;
        text-align: left;
        margin-top: 10px;
        font-size: 15px;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      .owner-name {
        color: gray;
        // color: $blueClr;
        text-transform: uppercase;

        font-size: 10px;
        margin-top: 10px;
      }
      .date,
      .price {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        color: gray;
        // color: $blueClr;
        padding-left: 20px;
        padding-top: 10px;
        padding-right: 5px;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        span {
          sup {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.rating-star-wraper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  column-gap: 3px;
  align-items: center;
  padding-top: 10px;
  color: #f29011;
}
.input-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      width: 12px;
    }
  }
  .minus-wraper {
    background-color: silver;
    > svg {
      color: $blueSea;
    }
  }
  .plus-wraper {
    background-color: $blueSea;
    > svg {
      color: white;
    }
  }
  input {
    width: 40px;
    border-bottom: 1px dashed $blueSea;
    height: 24px;
    // background-color: #f290116b;
    padding-left: 5px;
    border: none;
    outline: none;
    text-align: center;
  }
}
.quantity {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  height: 40px;
  justify-content: space-around;

  button {
    height: 24px;
    width: 70px;
    color: white;
    background-color: $orangeHeavy;
    border: none;
    outline: none;
    cursor: pointer;
  }
  svg {
    color: $orangeHeavy;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  // align-items: center;
  width: 100%;
  margin-top: 20px;
  .rotate-spinner {
    width: 50px !important;
    height: 50px;
    animation: rotate 1.4s linear infinite;
    -webkit-animation: rotate 1.4s linear infinite;
    -moz-animation: rotate 1.4s linear infinite;
    color: $orangeHeavy;
    // width: 174px;
    // height: 174px;
    position: relative;
    @keyframes rotate {
      to {
        transform: rotate(360deg);
      }
    }
    @-webkit-keyframes rotate {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-moz-keyframes rotate {
      to {
        transform: rotate(360deg);
      }
    }
  }
}
.product-list-template {
  margin-top: 20px;
  margin-bottom: 20px;
  > li {
    > ul {
      > li {
        width: 100%;
        padding-bottom: 20px;

        .list-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 10px;
          padding-right: 10px;
          // h2 {
          // }
          a {
            text-transform: uppercase;
            font-size: 21px;
            display: flex;
            color: #f25c11;
            font-weight: 600;
            @media (max-width: 768px) {
              font-size: 14px;
              // width: 155px;
            }
          }
        }
        ul {
          display: flex;
          // flex-wrap: wrap;
          padding: 20px;
          padding-bottom: 0;
          justify-content: flex-start;
          position: relative;
          // min-height: 400px;
          column-gap: 15px;
          // .swiper-wrapper {
          // }
          .swiper-container {
            // background-color: $silverMid;
            padding-top: 10px;
            .swiper-button-next,
            .swiper-button-prev {
              // display: none;
              color: $blueSea;

              &::before {
                font-size: 30px;
              }
              @media (max-width: 768px) {
                display: none;
              }
            }
          }
          .swiper-pagination {
            display: none;
          }
          .swiper-slide {
            height: auto !important;
            width: 226px !important;
            height: 300px !important;
            @media (max-width: 768px) {
              width: 155px !important;
              height: 250px !important;
            }
          }
          li {
            // border: 1px solid $silverMid;
            overflow: hidden;
            border-radius: 10px;
            // margin-left: 20px;
            margin-bottom: 30px;
            width: 226px;
            // &:first-child() {
            //   margin-left: 0px;
            // }
            @media (max-width: 768px) {
              width: 150px;
            }
          }
        }

        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          // width: 100%;
          flex: 1;
          justify-content: space-between;
          width: 100%;
          // width: 226px;
          .image-wrapper {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            overflow: hidden;
            flex: 1;
            height: 50%;
            img {
              &:first-child {
                max-width: 220px;
                height: 205px;
                width: 100%;
                object-fit: contain;
                object-position: center;
                @media (max-width: 800px) {
                  height: 120px;
                }
              }
              &:nth-child(2) {
                position: absolute;
                left: 0px;
                top: 0px;

                height: 75px;
              }
            }
          }
          .price {
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            color: black;
            padding-top: 10px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

.clear-filter-wraper {
  > svg {
    color: $liteOrange;
  }
  .active-filter {
    color: #f25c11;
  }
}

.top-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: capitalize;
}
.clear-filter-wraper {
  > img {
    width: 25px;

    fill: $blueSea;
  }
}

.dialog-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  // background-color: #000000a8;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;

  @media (max-width: 768px) {
    padding-top: 20px;
  }
  .shadow-space {
    background-color: #000000a8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .popup-content {
    background-color: #fff;
    max-width: 100%;
    min-width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 10px;
    @media (max-width: 768px) {
      .h2 {
        font-size: 16px;
      }
    }
    h2 {
      color: $blueSea;
      padding-top: 15px;
      font-size: 18px;
    }
    h3 {
      color: $orangeHeavy;
      padding-top: 15px;
      font-size: 14px;
    }
    .closepopup {
      display: grid;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;

      svg {
        font-size: 30px;
        color: $orangeHeavy;
        @media (hover) {
          transition: color 0.4s;
          &:hover {
            color: $blueSea;
          }
        }
      }
    }
  }
}
.drop-down {
  position: relative;
  display: inline-block;
  cursor: pointer;
  > span {
    color: $blueSea;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 16px;
    width: 70px;
    text-align: center;
  }
  // .drop-content {
  //   display: none;
  // }
  .drop-content {
    // display: none;
    position: absolute;
    background-color: white;
    min-width: 200px;
    box-shadow: $shadowBlue;
    z-index: 1;
    // left: 0px;
    right: 0px;
    top: 0px;
    top: 30px;
    border-radius: 5px;

    a,
    span {
      // color: $blueSea;
      padding: 12px 16px;
      text-decoration: none;
      display: block;

      &:hover {
        background-color: #ddd;
      }
      @media (hover) {
        &:hover {
          font-weight: 500;
          color: $blueSea;
        }
      }
      img {
        width: 20px;
        margin-right: 10px;
      }
      svg {
        width: 20px;
        margin-right: 10px;
      }
    }
    > hr {
      color: $silverMid;
    }
    a {
      display: flex;
      align-items: center;
      // margin-right: 5px;
    }
  }

  .drop-content {
    // display: block;
    position: absolute;
    background-color: white;
    z-index: 1;
    // left: 0;
    right: 0px;
    // top: 75px;
    box-shadow: $liteBlue;
    // min-width: 500px;
    // min-height: 100px;
    height: 40px;
    > ul {
      display: flex;
      height: inherit;
      // flex-direction: column;
      > li {
        height: 100%;
        padding: 3px;
        > a {
          height: 100%;
        }
      }
    }
  }
}
.options-dropdown {
  // @extend .drop-content;
  .opts {
    // display: none;
  }
  &:hover{
    .opts {
      display: block;
    }
  }

  .opts {
    display: block;
    box-shadow: inset 0 1px 3px $blueSea, 0 1px 3px $blueSea;
    height: auto !important;
    min-width: 150px !important;

    > ul {
      display: flex;
      flex-direction: column !important;
      li {
        padding: 10px;
        &:hover{
          background-color: $liteBlue !important;
        }
      }
    }
  }
}
.label-status {
  padding: 5px 10px;
  border: none;
  border-radius: 10px;
  color: white !important;
  width: 100px;
  text-align: center;
  text-transform: capitalize;
}
.Loading-Spinner {
  z-index: 104;
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  // opacity: 50%;
}

.export-btn {
  background-color: $orangeHeavy;
  color: white;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;

  font-weight: 600;
  cursor: pointer;
  margin-left: 20px;
  padding: 3px 5px;
}

