@use "./variable" as *;
* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
}
h1,
h2,
h3 {
  font-weight: normal;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
html {
  scroll-behavior: smooth;
}
body,
html {
  min-height: 100vh;
}
body {
  overflow-x: hidden;
  width: 100%;
  position: relative;
  margin: 0 auto;
  // padding-bottom: 176px;
  padding-bottom: 200px;
  @media (max-width: 768px) {
    // padding-bottom: 576px;
    // padding-bottom: 480px;
    // padding-bottom: 420px;
    padding-bottom: 512px;
  }
}

.body-overflow {
  overflow: hidden;
}

iframe {
  width: 100%;
  height: 500px;
}
img {
  display: block;
  max-height: 100%;
  max-width: 100%;
  height: auto;
}
fieldset {
  border: 1px solid $silverMid;
  padding: 5px;
  legend {
    text-transform: capitalize;
    color: gray;
  }
}

.shadow {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 50%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}
main {
  // margin-bottom: 50px;
  padding-top: $header-height;
}
.signIn-page {
  margin-bottom: 0;
}
.register-page {
  margin-bottom: 0;
}

.nav-menu {
  display: block;
  position: fixed;
  top: 0;
  left: -120vw;
  // z-index: 101;
  z-index: 200;
  height: 100%;
  transition: left 0.6s;
  width: 85%;
  .container {
    background-color: white;
    width: fit-content;
    width: -moz-fit-content;
    height: 100%;
    position: relative;
    overflow: auto;
    width: 100%;
    .nav-top-welcome {
      background-color: $blueSea;
      > img {
        position: absolute;
        max-width: 120px;
        top: -30px;
        left: -5px;
      }
      h1 {
        font-weight: bold;
        text-align: center;
        padding: 20px 0;
        color: white;
      }
      p {
        font-weight: bold;
        text-align: center;
        color: white;
        overflow: hidden;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 10px;
        font-size: 12px;
      }
    }

    ul {
      z-index: 3;
      position: relative;
      background-color: white;

      padding-top: 20px;

      > li {
        text-transform: capitalize;
        align-items: center;
        border-bottom: 1px solid $liteBlue;
        justify-content: space-between;
        position: relative;
        font-size: 15px;

        font-weight: bold;
        .admin {
          background-color: $silverMid;
        }
        a {
          padding: 15px 10px;
          padding-left: 20px;
          font-size: 15px;
          color: $blueSea;
          font-weight: bold;
          text-transform: uppercase;
          width: 100%;
          display: flex;
          align-items: center;
        }

        .arrowForward {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          border-radius: 50%;
          font-size: 30px;
          color: $blueSea;
        }
        &:first-child {
          margin-top: 0;
        }
        > ul {
          margin-left: 30px;
          margin-top: 10px;
          overflow: hidden;
          transition: max-height 0.6s;
          li {
            margin-bottom: 10px;
          }
        }
        svg {
          margin-right: 10px;
          &:nth-child(3) {
            margin-right: 0;
            background-color: grey;
            border-radius: 50%;
            margin-left: auto;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
    font-size: 30px;
    cursor: pointer;
  }
}

.bgOragnge {
  background-color: $orangeHeavy;
}
.bgBlue {
  background-color: $blueSea;
}
.react-confirm-alert-body {
  // .react-confirm-alert-overlay {
  //   background: $liteOrange !important;
  // }
  width: 100% !important;
  h1 {
    color: $blueSea;
    font-size: 22px;
  }
  .react-confirm-alert-button-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    > button {
      font-weight: 600;
      font-size: 14px;
      min-width: 70px;
      &:hover {
        background-color: $sand;
      }
    }
    .when_yes {
      background-color: $blueSea;
    }
  }
}

.picture-wrapper {
  position: relative;
  width: 100%;
  // max-height: 233px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  // flex: 1;
  // height: 50%;
  img {
    &:first-child {
      width: auto;
      max-width: 100%;
      height: 150px;

      object-fit: fill;
      object-position: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      @media (max-width: 768px) {
        max-width: 150px;
        max-height: 120px;
      }
    }
  }
  .sale-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 60px;
    height: 60px;
  }
}

.table-wraper {
  overflow-x: scroll;
  // width: 100%;
  padding: 10px;
  box-sizing: border-box;
  > table {
    border-collapse: collapse;
    // width: 100vw;
    width: 100%;
    padding: 10px;

    > caption {
      color: $orangeHeavy;
    }
    thead {
      height: 60px;
      position: sticky;
      left: 0;
      top: 0;
      z-index: 1;
      // background-color: $blueSea;
      // th {
      //   min-width: 80px;
      // }
      > tr {
        > th {
          text-align: left;
          background-color: $blueSea;
          color: white;
          border: 1px solid $silverMid;
          padding-left: 5px;
        }
      }
    }
    tbody {
      // min-height: 100px;

      > tr {
        max-height: 100px !important;
        cursor: pointer;
        position: relative;
        td {
          border: 1px solid $silverMid;
          padding: 8px;
          overflow-x: hidden;
          overflow-y: hidden;

          .img-wraper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            img {
              max-width: 150px;
              max-height: 150px;
            }
          }
        }
        .td-cell {
          color: $blueSea;
          max-height: 150px;
          > div {
            display: flex;
            justify-content: space-between;

            > .has-schedual-icon {
              color: $greenActive;
              > svg {
                width: 20px;
                height: 20px;
              }
            }
          }
          a {
            color: $blueSea;
            display: flex;
            align-items: center;
            column-gap: 5px;
          }
        }
        &:hover {
          background-color: $silverMid;
        }
        .nested-div-table {
          display: flex;
          flex-direction: row;
          row-gap: 10px;
          column-gap: 10px;
          position: absolute;
          display: none;
          // height: 250px !important;
        }
      }
      .spinner-wrapper {
        position: absolute;
      }
    }
  }
}

.refresh-icon-wrapper {
  > svg {
    width: 22px;
    height: 22px;
  }
}
.nothing-found-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    text-transform: capitalize;
    color: $blueSea;
  }
}
.categories-icon {
  width: 35px;
  color: $blueSea;
}
