@use "./variable" as *;

footer {
  padding: 20px $grid-space;
  background-color: $blueSea;
  margin-top: 20px;
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 10;
  .top-section {
    display: flex;
    margin-bottom: 50px;
    .left-section {
      width: 50%;
      @media (max-width: 600px) {
        display: none;
      }
      h1 {
        font-weight: bold;
        font-size: 18px;
      }
    }
    .right-section {
      width: 100%;
      display: flex;
      justify-content: center;
      @media (max-width: 600px) {
        width: 100%;
        justify-content: center;
      }
      a {
        padding: 20px 40px;
        color: white;
        border: 1px solid white;
        border-radius: 15px;
        font-size: 18px;
        height: 67px;
      }
    }
  }
  .bottom-section {
    display: flex;
    justify-content: space-between;
    // padding-bottom: 60px;
    .left-section {
      // position: relative;
      img {
        // position: absolute;
        // max-width: 136px;
        width: 136px;
      }
      .small-description {
        max-width: 397px;
        padding-top: 20px;
        @media (max-width: 846px) {
          display: none;
        }
      }
      @media (max-width: 600px) {
        display: none;
      }
    }
    .right-section {
      display: flex;
      justify-content: space-around;
      flex: 1;
      margin-left: 20px;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }
      .follow {
        @media (max-width: 768px) {
          margin-bottom: 30px;
        }
        h1 {
          margin-bottom: 10px;
          font-size: 18px;
        }
        ul {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          @media (max-width: 768px) {
            justify-content: flex-start;
          }
          li {
            margin-top: 0;
            margin-left: 10px;
            &:first-child {
              margin-left: 0;
            }
          }
          svg {
            font-size: 25px;
          }
        }
      }
      .designedBy {
        padding-top: 20px;
        a {
          color: white;
        }
        ul {
          li {
            // font-weight: 300;
            font-size: 11px;
          }
        }
      }
      .links {
        @media (max-width: 768px) {
          display: none;
        }
      }
      ul {
        @media (max-width: 931px) {
          // &:first-child {
          //   // display: none;
          // }
        }
        h1 {
          font-size: 18px;
        }

        li {
          font-size: 14px;
          margin-top: 20px;
          a {
            font-size: 14px;
            color: white;
            svg {
              font-size: 17px;
            }
          }
          &:first-child {
            margin-top: 0;
            // font-weight: bold;
          }
        }
      }
    }
  }
  .evolve-link {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  .contact {
    svg {
      margin-right: 10px;
    }
  }
}
