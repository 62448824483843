@use "./variable" as *;
.import-page {
  padding-top: $header-height;
  .top-page {
    padding: 10px 10px 10px 10px;
    color: $blueSea;
    font-size: 14px;
    > input {
      // padding: 3px 5px;
      // background-color: white;
      // background-color: $orangeHeavy;
    }
    .file-info {
      padding-left: 20px;

      // > p {
      //   max-width: 100px;
      //   overflow: hidden;
      // }
      button {
        width: 150px;
        text-align: center;
        border-radius: 10px;
        border: none;
        height: 30px;
        color: $graySilver;
        text-transform: capitalize;
        font-size: 14px;
      }
      .active-btn {
        cursor: pointer;
        color: white;
        background-color: $orangeHeavy;
        &:hover {
          background-color: $sand;
        }
      }
    }
  }
}
