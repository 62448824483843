@use "./variable" as *;

.register-page {
  height: calc(100vh - #{$header-height});
  width: 100%;
  position: relative;
  min-height: 840px;
  border: 1px solid $sand;
  padding-top: $header-height;
  .image-wrapper {
    height: 100%;
    width: 100%;
    img {
      min-height: 100%;
      filter: blur(7px);
      width: 100%;
      object-fit: cover;
      object-position: 0% 0%;
    }
  }
  .content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 $grid-space;
    padding-top: 30px;
    justify-content: center;
    h1 {
      font-size: 40px;
      font-weight: bold;
      color: $orangeHeavy;
    }
    span {
      color: $blueSea;
    }
    form {
      margin-top: 40px;
      @media (max-width: 600px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      ul {
        width: 500px;
        @media (max-width: 600px) {
          width: 92%;
        }
        li {
          position: relative;
          width: 100%;
          &:not(:first-child) {
            margin-top: 20px;
          }
          input {
            width: 100%;
            padding: 20px 10px;
            background-color: white;
            outline: none;
            border: 1px solid $sand;
          }
        }
      }
      .loader-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
      button {
        background-color: $orangeHeavy;
        outline: none;
        width: 500px;
        padding: 20px 10px;
        color: white;
        border: none;
        margin-top: 50px;
        font-size: 20px;
        display: flex;

        justify-content: center;
        cursor: pointer;
        @media (max-width: 600px) {
          width: 92%;
        }
        @media (hover) {
          transition: color 0.5s, background-color 0.5s;
          &:hover {
            color: $orangeHeavy;
            background-color: white;
          }
        }
      }
      .disable {
        display: none;
      }
      .loader {
        border: 4px solid #f3f3f3;
        border-radius: 50%;
        border-top: 4px solid $orangeHeavy;
        width: 60px;
        height: 60px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
      }

      /* Safari */
      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .file {
      opacity: 0;
      width: 0.1px;
      height: 0.1px;
      position: absolute;
    }

    .file-input label {
      display: block;
      position: relative;
      width: 200px;
      height: 50px;
      border-radius: 25px;
      background: $orangeHeavy;
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      transition: transform 0.2s ease-out;
    }

    .file-name {
      position: absolute;
      bottom: -20px;
      left: 10px;
      font-size: 0.85rem;
      color: black;
    }

    input:hover + label,
    input:focus + label {
      transform: scale(1.02);
    }

    /* Adding an outline to the label on focus */
    input:focus + label {
      outline: 1px solid #000;
      outline: -webkit-focus-ring-color auto 2px;
    }
    /* label {
      span {
        color: $orangeHeavy;
        font-size: 20px;
        border-bottom: 1px solid $orangeHeavy;
        font-weight: bold;
        svg {
          margin-right: 5px;
        }
      }
    }*/

    .error {
      color: red;
      position: absolute;
      bottom: -18px;
      left: 0;
    }
    .file-error {
      bottom: -37px;
    }
    > div {
      margin-top: 20px;
      color: white;
      font-size: 15px;
      a {
        color: $orangeHeavy;
      }
    }
  }
  .registerRadio {
    margin-left: 40px;
    display: flex;
  }
  .upload-file {
    display: flex;
    align-items: center;
    input {
      width: auto !important;
    }
  }
  .registerRadio {
    align-items: center;
    display: flex;
    .firstlabel {
      margin-right: 20px;
    }
  }
  .question-account {
    span {
      color: $graySilver;
    }
    a {
      text-decoration: underline;
    }
  }
}
