@use "./variable" as *;
.accordion {
  display: inline-block;
  text-align: left;
  // margin: 1%;
  width: 100%;

  position: relative;

  // &:hover {
  //   // max-height technique
  //   .accordion-content {
  //     // If use the "height: auto", the effect not works. Is necessary some value for the CSS create a CSS animate, and we can use "max-height" with a great value for emulate this effect.
  //     max-height: 1000px;
  //     // content
  //   }
  // }
}

.accordion-content {
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  background: #e5feff;
  overflow: hidden;
  // "height: 0" not work with css transitions
  max-height: 0;
}
.maxHeight {
  max-height: 1000;
}

.accordion-inner {
  padding: 0 15px;
  //**************
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 20px;
}

.accordion-toggle {
  -webkit-transition: background 0.1s linear;
  -moz-transition: background 0.1s linear;
  -ms-transition: background 0.1s linear;
  -o-transition: background 0.1s linear;
  transition: background 0.1s linear;

  background: $blueSea;
  border-radius: 3px;
  color: #fff;
  display: block;
  font-size: 20px;
  margin: 0 0 10px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    // background: darken(#00b8c9, 15%);
    background: $blueSky;
  }
}
