@use "./variable" as *;

.single-request {
  .request-header {
    padding: 10px;

    display: flex;
    justify-content: space-between;
    > ul {
      flex: 1;
      width: 100%;
      > li {
        color: $blueSea;
        text-transform: capitalize;
        margin-bottom: 10px;
        span {
          color: black;
        }
      }
    }
  }
}
