$grid-space: 1%;

$header-height: 110px;
$mobile-header-height: 85px;
// $header-innerheight: 52px;
$orangeHeavy: hsl(20, 90%, 51%);
$greenActive: hsl(120, 100%, 30%);
$blueSea: hsl(203, 82%, 37%);
$blueSky: hsl(203, 90%, 49%);
$sand: hsl(34, 90%, 51%);
$graySilver: hsla(0, 2%, 28%, 0.6);
$liteBlue: hsla(203, 82%, 37%, 0.098);
$liteOrange: hsla(20, 90%, 51%, 0.149);
$swiper-navigation-size: 30px;
$silverMid: hsla(0, 0%, 77%, 0.2);
$shadowBlue: inset 0 1px 3px $blueSea, 0 1px 3px $blueSea;
$rsasii: #707070;
$cloudyColor: rgb(238, 238, 238);
