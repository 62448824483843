@use "./variable" as *;

.product-detail {
  display: flex;
  margin-bottom: 0;
  border: 1px solid $sand;
  margin-left: 20px;
  margin-right: 20px;
  // min-height: 700px;
  padding-top: $header-height;
  @media (max-width: 930px) {
    border: none;
  }
  @media (max-width: 768px) {
    padding-top: $mobile-header-height;
  }
  .page-detail {
    margin-top: 30px;
    padding: 0 $grid-space;
    // padding-bottom: 100px;
    width: calc(100% - 350px);
    @media (max-width: 930px) {
      width: 100%;
    }
  }
  .top-section {
    display: flex;
    padding: 10px 20px;
    align-items: center;
    background-color: #cfe2ee;
    border-radius: 20px;
    @media (max-width: 768px) {
      padding: 10px;
    }
    h1 {
      text-transform: uppercase;
      font-size: 21px;
      display: flex;
      color: $blueSea;
      font-weight: 600;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .mySwiper {
      .swiper-button-next {
        // width: 10px !important;
        // color: $orangeHeavy;
        // background-color: $blueSea;
        // color: white;
        // padding: 10px;

        ::after {
          width: 10px !important;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
    ul,
    .swiper-container {
      display: flex;
      flex: 1;
      margin-left: 30px;
      .swiper-button-next,
      .swiper-button-prev {
        width: 20px !important;
        @media (max-width: 768px) {
          display: none !important;
        }
      }
      .swiper-wrapper {
        // justify-content: center;
        justify-content: flex-start;
        @media (max-width: 1100px) {
          // justify-content: flex-start !important;
        }
      }
      @media (max-width: 1200px) {
        // margin-left: 40px;
      }

      > li,
      .swiper-slide {
        width: auto !important;

        .image-wrapper {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 135px;
            width: 100%;
            min-width: 135px;
            height: 135px;
            &:first-child {
              width: 100%;
              object-fit: contain;
              object-position: center;
              border-radius: 15px;
            }
          }
          .sale-icon {
            position: absolute;
            right: -0px;
            top: 0px;
            max-width: 60px;
          }
          .new-product-icon {
            position: absolute;
            left: -9px;
            top: -8px;
            max-width: 60px;
          }
        }
      }
    }
  }

  .page-content {
    display: flex;
    margin-top: 30px;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #f29011;
    @media (max-width: 1220px) {
      flex-direction: column;
      width: 100%;
      border: none;
    }
    .image-wrapper {
      // box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 1);
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 300px;
      padding: 10px 20px;
      position: relative;
      img {
        max-width: 300px;
        max-height: 250px;
        @media (max-width: 500px) {
          max-width: 220px;
          max-height: 150px;
        }
      }

      &:first-child {
        max-width: 300px;
        max-height: 250px;
        object-fit: cover;
        object-position: center;
        @media (max-width: 500px) {
          max-width: 220px;
          max-height: 150px;
        }
      }
      .discount-icon {
        position: absolute;
        top: 4px;
        right: 0px;
        max-width: 75px !important;
      }
    }
    .details {
      // height: 120px;
      // background-color: #13510a80;
      // border-radius: 30px;
      // margin-left: -50px;

      width: 100%;
      max-width: 500px;
      position: relative;
      @media (max-width: 860px) {
        margin: 0;
        margin-top: 10px;
      }
      h2 {
        font-size: 16px;
        padding: 20px 0px;
        color: $blueSea;
        // padding-left: 100px;
        padding-right: 100px;
        @media (max-width: 860px) {
          font-size: 14px;
          padding: 10px;
        }
      }
      .price {
        display: flex;
        justify-content: space-between;

        font-size: 16px;
        padding-top: 30px;
        padding-bottom: 10px;
        @media (max-width: 860px) {
          font-size: 14px;
        }
        span {
          // opacity: 0.6;
          font-size: 16px;
        }
      }
      .input-container {
        display: flex;
        justify-content: space-between;

        font-size: 16px;
        align-items: center;
        padding-bottom: 30px;
        border-bottom: 1px solid $sand;
        @media (max-width: 860px) {
          font-size: 14px;
        }
      }
      .description {
        padding: 20px 0;
        font-size: 16px;
        .about-this-item {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 10px;
          h3 {
            font-size: 14px;
          }
          @media (max-width: 800px) {
            // padding: 10px;
          }
          .add-to-cart {
            background-color: $orangeHeavy;

            font-size: 14px;
            color: white;
            align-self: flex-end;
            padding: 10px 20px;
            opacity: 100%;
            cursor: pointer;
            // width: 100px;
            @media (hover) {
              transition: color 0.5s, background-color 0.5s;
              &:hover {
                color: $orangeHeavy;
                background-color: white;
              }
            }
            @media (max-width: 860px) {
              font-size: 12px;
              padding: 10px;
            }
          }
        }
        // div {
        //   font-size: 16px;

        //   @media (max-width: 860px) {
        //     font-size: 14px;
        //   }
        // }
      }
      .price,
      .input-container,
      .description {
        padding-right: 100px;
        @media (max-width: 860px) {
          padding-left: 30px;
          padding-right: 30px;
          padding-top: 0px;
        }
      }
      .description {
        padding-right: 40px;
        flex: 1;
        @media (max-width: 986px) {
          padding-right: 20px;
        }
      }
      .footer-product {
        display: flex;
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    // display: none;
    color: $blueSea;

    &::before {
      font-size: 30px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}
