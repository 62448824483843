@use "./variable" as *;
.users-page {
  padding-top: $header-height;
  @media (max-width: 768px) {
    padding-top: $mobile-header-height;
  }
  .table-wraper {
    .active-user {
      color: $orangeHeavy;
    }
    .not-active {
      color: $blueSea;
    }
  }
  .th-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    // justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    > label {
      font-size: 14px;
      text-transform: capitalize;
    }
    .search-input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      > input {
        width: 100%;
        height: 20px;
        padding-left: 10px;
        border: none;
        outline: none;
      }
      > button {
        width: 20px;
        height: 20px;
        border: none;
        // background-color: ;
        display: flex;
        justify-content: center;
        align-items: center;
        > svg {
          color: $orangeHeavy;
        }
      }
    }
  }
  // .th-taxid {
  // }
}
