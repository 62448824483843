@use "./variable" as *;
.home-page {
  display: flex;
  align-items: stretch;
  margin-bottom: 0;
  border: 1px solid $sand;
  // margin: 1%;
  padding-top: $header-height;
  @media (max-width: 768px) {
    padding-top: $mobile-header-height;
  }
  .page-content {
    width: calc(100% - 320px);
    // max-height: 1500px;
    // overflow-y: scroll;
    // padding-left: 20px;
    padding: 20px;
    @media (max-width: 930px) {
      width: 100%;
      padding: 5px;
      // max-height: none;
      // overflow-y: unset;
    }
    .top-section {
      width: 100%;
      padding: 20px;
      background-color: $liteBlue;
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: center;
      height: 430px;
      border-radius: 20px;
      margin-bottom: 20px;
      //   height: calc(100vh - #{$header-height});
      @media (max-width: 768px) {
        // margin-top: 50px;
        height: auto;
        //height: calc(80vh - #{$header-height});
      }
      @media (max-width: 500px) {
        //height: calc(80vh - 120px);
      }
      .phonix-wraper {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        // max-width: 600px;
      }

      .swiper-pagination-bullet {
        background-color: white;
      }
      .swiper-slide {
        width: 100% !important;
      }
      .swiper-pagination {
        display: flex;
        justify-content: center;
        width: calc(100vw - 350px);
        @media (max-width: 930px) {
          width: 100vw;
        }
      }
    }
    // .offer-list {
    //   display: flex;
    //   padding: 10px 20px;
    //   align-items: center;
    //   background-color: #cfe2ee;
    //   border-radius: 20px;
    //   margin-bottom: 20px;
    //   margin-top: 20px;
    //   @media (max-width: 925px) {
    //     padding: 10px;
    //     margin-bottom: 10px;
    //     margin-top: 10px;
    //   }
    //   h1 {
    //     a {
    //       text-transform: uppercase;
    //       font-size: 21px;
    //       display: flex;
    //       color: $blueSea;
    //       font-weight: 600;
    //       @media (max-width: 700px) {
    //         font-size: 14px;
    //         padding-right: 5px;
    //       }
    //     }
    //   }

    //   ul,
    //   .swiper-container {
    //     display: flex;
    //     flex: 1;
    //     margin-left: 30px;
    //     // .swiper-button-prev {
    //     //   // opacity: 1;
    //     //   width: 30px;
    //     // }
    //     .swiper-button {
    //       height: 20px;
    //     }
    //     .swiper-wrapper {
    //       justify-content: center;
    //       @media (max-width: 900px) {
    //         justify-content: flex-start !important;
    //       }
    //       img {
    //         object-fit: cover !important;
    //       }
    //     }
    //     @media (max-width: 925px) {
    //       margin-left: 0px;
    //     }
    //     > li,
    //     .swiper-slide {
    //       width: auto !important;
    //       a {
    //         height: auto;
    //       }
    //       .image-wrapper {
    //         position: relative;
    //         width: 100%;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         &:first-child {
    //           max-width: 125px;
    //           width: 100%;
    //           min-width: 125px;
    //           height: 125px;

    //           object-fit: cover;
    //           object-position: center;
    //           border-radius: 15px;
    //         }
    //         .discount-icon {
    //           position: absolute;
    //           top: -3px;
    //           max-width: 75px !important;
    //         }
    //         img {
    //           object-fit: cover;
    //         }
    //       }
    //     }
    //   }
    // }
    // .product-list {
    //   // padding: 0px $grid-space;
    //   padding-top: 0;

    //   > ul {
    //     display: flex;
    //     flex-wrap: wrap;
    //     margin-top: 30px;
    //     // justify-content: center;
    //     // margin-left: -2%;
    //     @media (max-width: 600px) {
    //       margin-top: 20px;
    //       justify-content: center;
    //     }
    //     > li {
    //       // margin-bottom: 50px;
    //       // padding-bottom: 40px;
    //       // margin-left: 2%;
    //       // margin-bottom: 20px;
    //       position: relative;
    //       width: 100%;
    //       // @media (max-width: 600px) {
    //       //   width: 100%;
    //       // }

    //       .swiper-container {
    //         padding: 0 40px;
    //         // padding-top: 40px;
    //         // max-width: 100%;
    //       }
    //       .content {
    //         width: fit-content;
    //         width: -moz-fit-content;
    //         width: 220px;
    //         display: flex;
    //         flex-direction: column;
    //         align-items: center;
    //         color: black;
    //         border-radius: 15px;

    //         .image-wrapper {
    //           position: relative;
    //           width: 100%;
    //           display: flex;
    //           justify-content: center;
    //           align-items: center;
    //           overflow: hidden;
    //           height: 200px;
    //           @media (max-width: 800px) {
    //             height: 120px;
    //           }

    //           &:first-child {
    //             max-width: 200px;
    //             width: 100%;
    //             max-height: 200px;
    //             object-fit: cover;
    //             object-position: center;
    //             border-radius: 15px;
    //           }

    //           .sale-icon {
    //             position: absolute;
    //             right: -20px;
    //             top: -21px;
    //             max-width: 60px;
    //           }
    //         }

    //         // h3 {
    //         //   text-align: center;

    //         //   font-size: 17px;
    //         //   color: black;
    //         //   margin-top: 5px;
    //         // }
    //       }
    //     }
    //   }

    .on-sale {
      position: relative;

      @media (max-width: 800px) {
        padding-left: 0px;
      }
      .image-wrapper {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          min-height: 150px;
          max-height: 300px;
          object-fit: cover;
          object-position: center;
          max-height: 300px;
        }
        &::before {
          content: "";
          background-color: #0000004d;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .content {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        left: 0;
        z-index: 2;
        height: 100%;
        justify-content: space-between;
        width: 100%;
        .samll-description {
          margin-left: 60px;
          color: white;
          padding-left: 40px;
          padding-right: 40px;
          font-size: 25px;
          font-weight: normal;
          // background-color: #0000004d;
          max-width: 400px;

          @media (max-width: 820px) {
            position: absolute;
            font-size: 20px;
            margin-left: 30px;
            width: auto;
            padding-right: 20px;
            padding-left: 20px;
          }
        }

        a {
          color: white;

          font-size: 20px;
          margin-left: 70px;
          margin-bottom: 40px;
          background-color: #0000004d;
          padding: 15px 35px;
          align-self: flex-end;
          @media (max-width: 820px) {
            position: absolute;
            font-size: 20px;
            margin-right: 30px;
            padding: 15px 20px;
            margin-bottom: 0;
            right: 20px;
            bottom: 20px;
          }
        }
      }
    }

    .add-to-cart {
      text-align: center;
      padding-bottom: 50px;
      span {
        background-color: $orangeHeavy;
        padding: 10px;
        color: white;
        cursor: pointer;
      }
    }
  }
}
