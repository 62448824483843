@use "./variable" as *;
.notes-page {
  background-color: $cloudyColor;
  min-height: 100%;
  .tag-filter {
    width: 100%;
    background-color: $liteBlue;
    height: 30px;
    > ul {
      display: flex;
      height: 100%;
      align-items: center;
      padding-left: 10px;
      > li {
        margin-left: 15px;
      }
    }
  }
  .notes {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    > .note-item {
      margin-right: 10px;
      padding: 10px;
      .top-title {
        > input {
          width: 100%;
          border: none;
        }
      }
      > textarea {
        border: none;
      }
    }
  }
}
