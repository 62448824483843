@use "./variable" as *;
.orders-page {
  .dialog-popup {
    .popup-content {
      padding: 10px;
      max-width: 100%;
      min-width: 375px;
      padding: 50px 20px;
      align-items: flex-start;
      overflow-y: scroll;
      max-height: 700px;

      > section {
        > ul {
          > li {
            text-transform: capitalize;
            color: $orangeHeavy;
            margin-bottom: 10px;
            > span {
              color: black;
            }
          }
        }
      }
      @media (max-width: 600px) {
        width: 90%;
      }
    }
  }
}
// .td-cell {
//   .mailto-a {
//     color: $blueSea;
//   }
// }
