@use "./variable" as *;

header {
  position: fixed;
  width: 100%;
  z-index: 102;
  height: $header-height;
  @media (max-width: 768px) {
    height: $mobile-header-height;
  }
  .first-header {
    width: 100%;
    height: 23%;
    background-color: #1170aa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding: 0px 5px;
    @media (max-width: 800px) {
      height: 22px;
    }
  }
  .second-header {
    width: 100%;
    height: 77%;
    padding: 5px $grid-space;
    display: flex;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    position: relative;
    padding: 0px 5px;

    // z-index: 5;
    // box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.47);
    // margin-bottom: 20px;
    @media (max-width: 1024px) {
      height: 60px;
    }
    // @media (max-width: 500px) {
    //   padding-bottom: 60px;
    //   height: 120px;
    // }

    .image-wrapper {
      position: absolute;
      // top: 12px;
      left: 10px;
      @media (max-width: 900px) {
        position: static;
        margin-left: 5px;
      }
      @media (max-width: 500px) {
        display: none;
      }

      img {
        max-width: 110px;
        width: 80px;
        height: 50px;
        @media (max-width: 1024px) {
          max-width: 100px;
        }
        @media (max-width: 800px) {
          max-width: 80px;
        }
      }
    }
    .menu-icon {
      margin-left: 60px;
      @media (max-width: 1024px) {
        margin-left: 60px;
      }
      @media (max-width: 900px) {
        display: none;
      }
      svg {
        font-size: 40px;
        cursor: pointer;
      }
    }
    .category-list {
      display: none;
      @media (max-width: 930px) {
        display: block;
        z-index: 250;
      }
    }
    .burger-menu {
      display: none;

      @media (max-width: 930px) {
        display: block;
        color: $orangeHeavy;
      }
      svg {
        font-size: 30px;
        cursor: pointer;
      }
    }
    > nav {
      margin-left: 50px;
      // min-width: 350px;
      // flex: 1;
      @media (max-width: 900px) {
        display: none;
      }
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        > li {
          // width: 91px;
          // min-width: 70px;
          width: 100%;
          width: fit-content;
          padding: 10px;
          a {
            display: grid;
            place-items: center;
            font-size: 14px;
            letter-spacing: 1px;
            color: $blueSea;
            text-transform: uppercase;
            position: relative;
          }
          &:first-child {
            a {
              margin-left: 0;
            }
          }
          // .active-page {
          //   color: $orangeHeavy;

          //   @media (max-width: 1024px) {
          //     height: 37px;
          //   }
          //   &::after {
          //     // content: '';
          //     height: 70px;
          //     width: 120px;
          //     background-color: $orangeHeavy;
          //     position: absolute;
          //     top: -23px;
          //     z-index: -1;
          //     border-radius: 15px;
          //     // border-bottom: 1px solid $orangeHeavy;
          //   }
          // }
        }
      }
    }
    .auth {
      margin-right: 10px;
      font-size: 14px;
      //
      letter-spacing: 1px;
      color: $blueSea;
      text-transform: uppercase;
      display: grid;
      place-items: center;
      cursor: pointer;
      @media (hover) {
        transition: color 0.4s;
        &:hover {
          color: $orangeHeavy;
          border-bottom: 1px solid $orangeHeavy;
        }
      }
      @media (max-width: 900px) {
        margin-right: 10px;
        font-size: 12px;
      }
      @media (max-width: 500px) {
        display: none;
      }
      a {
        font-size: 14px;
        letter-spacing: 1px;
        color: $blueSea;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
    .search-container {
      display: flex;
      height: 37px;
      flex: 1;
      margin: 0 10px;
      border-radius: 5px;
      border: 1px solid $orangeHeavy;
      @media (max-width: 1024px) {
        margin: 0 20px;
      }

      .search-icon {
        color: white;
        // background-color: #f25c11;
        display: grid;
        place-items: center;
        // height: 37px;
        // width: 45px;
        padding-left: 10px;

        border: none;
        // border-bottom-left-radius: 5px;
        // border-top-left-radius: 5px;
        // margin-left: -48px;
        cursor: pointer;
        @media (max-width: 500px) {
          // height: 37px;
          // width: 37px;
          // margin-right: -38px;
        }
      }
      input {
        border-radius: 5px;
        width: 100%;
        padding-left: 10px;
        outline: none;
        height: 100%;
        padding-right: 50px;
        // border: 1px solid $sand;

        border: none;
        // border-left: none;
        &::placeholder {
          color: #b8b8b8;
          font-size: 13px;
          opacity: 100%;
        }
      }
    }
    .cart-container {
      display: flex;
      align-items: center;
      position: relative;
      text-transform: uppercase;
      justify-content: center;
      width: 40px;
      height: 35px;
      @media (max-width: 800px) {
        width: 35px;
      }
      svg {
        font-size: 20px;
        color: $orangeHeavy;
        position: relative;
        z-index: 2;
      }
      div {
        letter-spacing: 1px;
        color: black;
        // margin-right: 5px;
        position: relative;
      }
      span {
        position: absolute;
        top: -15px;
        right: 10px;
        font-family: "bahnschrift";
        font-weight: bold;
        color: $orangeHeavy;
        font-size: 16px;
        z-index: 10;
        @media (max-width: 800px) {
          font-size: 14px;
        }
      }
    }
    // .active-page {
    //   color: $orangeHeavy;

    //   &::after {
    //     // content: '';
    //     height: 70px;
    //     width: 120px;
    //     background-color: $orangeHeavy;
    //     position: absolute;
    //     top: -23px;
    //     z-index: -1;
    //     border-radius: 15px;
    //   }
    // }
  }
}
