@use "./variable" as *;
.category-list {
  transition: left 0.6s;
  overflow: auto;
  min-width: 320px;
  // margin-right: 20px;
  @media (max-width: 930px) {
    width: 90%;
    display: block;
    position: fixed;
    top: 0;
    left: -120vw;
    height: 100%;
    overflow-y: scroll;
  }
  .container {
    background-color: white;
    // width: fit-content;
    border-right: 1px solid $sand;
    width: -moz-fit-content;
    height: 100%;
    position: relative;
    @media (max-width: 1024px) {
      width: 100%;
    }
    // svg {
    //   color: $orangeHeavy;
    //   width: 25px;
    //   height: 25px;
    //   padding-right: 10px;
    // }
    .categoriesTop {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
      border-bottom: 1px solid $sand;
      font-weight: 600;
      svg {
        color: $orangeHeavy;
        width: 25px;
        height: 22px;
        padding-right: 10px;
      }
      h1 {
        text-align: center;
        padding: 20px 0;
        color: $blueSea;
        text-transform: uppercase;
        align-items: center;
      }
      .close {
        color: #f25c11;
        width: 37px;
        height: 50px;
        padding-right: 10px;
      }
    }

    > ul {
      z-index: 3;
      position: relative;
      background-color: white;
      min-width: 300px;
      width: 100%;
      // width: fit-content;
      width: -moz-fit-content;
      overflow-y: scroll;
      max-height: 1000px;

      //    padding-top: 20px;
      // @media (max-width: 1024px) {
      //   width: 100%;
      // }
      > li {
        padding: 10px;
        padding-left: 20px;
        text-transform: capitalize;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        a {
          font-size: 15px;
          padding: 5px;
          color: black;
          width: 100%;
          @media (hover) {
            transition: color 0.4s;
            &:hover {
              color: $orangeHeavy;
              border-bottom: 1px solid $orangeHeavy;
            }
          }
          span {
            @media (hover) {
              transition: color 0.4s;
              &:hover {
                color: $orangeHeavy;
                border-bottom: 1px solid $orangeHeavy;
              }
            }
          }
        }
        > a {
          font-size: 20px;
          display: block;
          width: 100%;
          &:hover {
            color: $orangeHeavy;
            border-bottom: none;
            @media (hover) {
              transition: color 0.4s;
              &:hover {
                span {
                  color: $orangeHeavy;
                  border-bottom: 1px solid $orangeHeavy;
                }
              }
            }
          }
        }
        .arrowDropdown {
          position: absolute;
          right: 20px;
          top: 8px;
          cursor: pointer;
          border-radius: 50%;
          color: #f25c11;
          padding: 3px;
          width: 30px;
          height: 30px;
          font-size: 42px;
          @media (hover) {
            transition: opacity 0.4s;
            &:hover {
              opacity: 1 !important;
            }
          }
        }
        &:first-child {
          margin-top: 0;
        }
        > ul {
          margin-left: 30px;
          margin-top: 10px;
          overflow: hidden;
          transition: max-height 0.6s;
          li {
            margin-bottom: 10px;
          }
        }
        svg {
          margin-right: 10px;
          &:nth-child(3) {
            margin-right: 0;
            background-color: grey;
            border-radius: 50%;
            margin-left: auto;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
    font-size: 30px;
    cursor: pointer;
  }

  /*.active{
          background-color: white;
          color: #0076D9;
          border-radius: 30px 0% 0 30px;
          box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.75);
  
          svg{
              &:nth-child(3){
                  color: white;
              }
          }
      }*/
}
