@use "./variable" as *;
.about-page {
  display: flex;
  align-items: stretch;
  margin-bottom: 0;
  // border: 1px solid $sand;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: $header-height;
  @media (max-width: 768px) {
    padding-top: $mobile-header-height;
  }
  .page-content {
    // width: calc(100% - 350px);
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 930px) {
      width: 100%;
    }
    .img-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      img {
        height: auto;
        // width: 100%;
        max-width: 300px;
        object-fit: cover;
        object-position: center;
        @media (max-width: 600px) {
          max-width: 250px;
        }
      }
    }

    .aboutUs {
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
      h1 {
        font-size: 32px;
        color: $blueSea;
        margin-bottom: 20px;
      }
      p {
        color: $sand;
        font-size: 25px;
        padding-top: 10px;
        @media (max-width: 600px) {
          font-size: 18px;
        }
      }
    }
  }
}
