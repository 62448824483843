@use "./variable" as *;
.scheduals-page {
  padding-top: $header-height;
  @media (max-width: 768px) {
    padding-top: $mobile-header-height;
  }
  .ViewSchedualed {
    border-bottom: 2px solid $sand;
  }
}
.top-page {
  .th-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    // justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    > label {
      font-size: 14px;
      text-transform: capitalize;
    }
    .search-input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      border: 1px solid $orangeHeavy;
      > input {
        width: 100%;
        height: 20px;
        padding-left: 10px;
        border: none;
        outline: none;
      }
      > button {
        width: 20px;
        height: 20px;
        border: none;
        // background-color: ;
        display: flex;
        justify-content: center;
        align-items: center;
        > svg {
          color: $orangeHeavy;
        }
      }
    }
  }
}
.product-content {
  display: flex;
  flex-direction: column;
  border: 1px solid $silverMid;
  padding: 10px;
  width: 200px;
  > .picture-wrapper {
    > img {
      width: 130px;
      height: 130px;
    }
  }
}
.ViewSchedualed {
  width: 100%;
  padding: 10px 20px;
  overflow-y: scroll;
  .top-schedual {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    > fieldset {
      flex: 1;
      > ul {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        > li {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          > label {
            color: $blueSea;
          }
        }
      }
    }
  }

  > h2 {
    color: $orangeHeavy;
    text-transform: capitalize;
    text-align: center;
  }
  > h3 {
    color: $blueSea;
    text-transform: capitalize;
  }
  .options-footer {
    display: flex;
    justify-content: flex-end;
    // position: absolute;
    // right: 0px;
    // bottom: 0px;
    > ul {
      display: flex;

      li {
        padding: 5px;
        button {
          border: none;
          color: white;
          padding: 10px;
          text-transform: capitalize;
          cursor: pointer;
          transition: background-color 0.6s;
          width: 100px;
        }

        > .edit-switch {
          color: $blueSea;
          border: 1px solid $blueSea;
          text-transform: uppercase;
          font-weight: 600;
          &:hover {
            background-color: $blueSea;
            color: white;
          }
        }
        > .deactivate-btn {
          color: $orangeHeavy;
          border: 1px solid $orangeHeavy;
          background-color: white;
          text-transform: uppercase;
          font-weight: 600;
          &:hover {
            background-color: $orangeHeavy;
            color: white;
          }
        }
        > .activate-btn {
          background-color: white;
          color: $greenActive;
          border: 1px solid $greenActive;
          text-transform: uppercase;
          font-weight: 600;
          &:hover {
            // transition: 0.5s, color 0s 0.5s, background-color 0s 0.5s;
            background-color: $greenActive;
            color: white;
          }
        }
      }
    }
  }
}
.PosUserInfo-wraper {
  > ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
    padding-left: 10px;
    > li {
      display: flex;
      justify-content: space-between;
      > label {
        color: $orangeHeavy;
        width: 170px;
        text-transform: capitalize;
      }
      > span {
        color: $blueSea;
      }
    }
  }
}
.disabled-schedual {
  border: none;
  width: 100%;
  height: 30px;
  text-transform: capitalize;
}
.update-schedual-btn {
  border: none;
  width: 100%;
  height: 30px;
  text-transform: capitalize;
  color: white;
  border: none;
  background-color: $blueSea;
  cursor: pointer;
  transition: background-color 0.6s;
  &:hover {
    background-color: $blueSky;
  }
}
