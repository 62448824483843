@use "./variable" as *;
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clr-blue {
  color: $blueSea;
}

.clr-orange {
  color: $blueSea;
}
.borderBlue {
  border: 1px solid $blueSea;
}
.borderOrange {
  border: 1px solid $orangeHeavy;
}
.styledButton {
  background-color: $blueSea;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  &:hover() {
    background-color: $orangeHeavy;
  }
}

.active-page {
  color: $orangeHeavy !important;

  @media (max-width: 1024px) {
    height: 37px;
  }
  &::after {
    // content: '';
    height: 70px;
    width: 120px;
    background-color: $orangeHeavy;
    position: absolute;
    top: -23px;
    z-index: -1;
    border-radius: 15px;
    // border-bottom: 1px solid $orangeHeavy;
  }
}
.flexWrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 20px;
}
.flexBtw {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  background-color: silver;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  > svg {
    color: white;
    cursor: pointer;
  }
}
.pointer {
  cursor: pointer;
}
.active-page {
  color: $orangeHeavy;
  // border-bottom: 2px solid $orangeHeavy;
}
