@use "./variable" as *;
.bottom-menu {
  display: none;
  .active-page {
    color: $orangeHeavy;
    // border-bottom: 2px solid $orangeHeavy;
  }
  @media (max-width: 600px) {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 101;
    background-color: white;
    height: 50px;
    padding: 0px 20px;
    // border-top: 1px solid $blueSea;
    box-shadow: 0px 0px 0px $silverMid, 0px -2px 2px $silverMid;
  }
  > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
    width: 100%;

    li {
      > a {
        color: $blueSea;
        display: flex;
        flex-direction: column;
        align-items: center;
        > svg {
          width: 25px;
          height: 22px;
        }
      }
    }
  }
  .customer-navs {
    justify-content: space-between !important;
    width: 100%;
  }
}
