@use "./variable" as *;

.contact-page {
  display: flex;
  align-items: stretch;
  margin-bottom: 0;
  // border: 1px solid $sand;
  padding-top: $header-height;
  @media (max-width: 768px) {
    padding-top: $mobile-header-height;
  }
  .page-content {
    width: calc(100% - 320px);
    padding: 20px;

    @media (max-width: 930px) {
      width: 100%;
    }
    .gmap_canvas {
      overflow: hidden;
      background: none !important;
      height: 500px;
      width: 100%;
    }
    .mapouter {
      position: relative;
      text-align: right;
      height: 500px;
      width: 100%;
    }
    .contact-wraper {
      display: flex;
      padding-top: 20px;
      padding-bottom: 20px;
      justify-content: center;

      .contact-information {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
        max-width: 350px;
        border: 1px solid $orangeHeavy;
        padding: 18px;
        flex: 1;
        h1 {
          font-size: 20px;
          color: $blueSea;
        }

        li {
          margin-top: 12px;
          a {
            font-size: 17px;
            color: black;
            display: flex;
            align-items: center;
            svg {
              margin-right: 10px;
              font-size: 20px;
              color: $orangeHeavy;
            }
          }
        }
        li:first-child {
          margin-top: 0px;
        }
      }
      .rightOrange {
        flex: 0.2;
        @media (max-width: 800px) {
          display: none;
        }
      }
    }
  }
}
