@use "./variable" as *;

.product-list-page {
  display: flex;
  min-height: 100vh;
  padding-right: 1%;
  border: 1px solid $sand;
  //padding-left: $grid-space;
  padding-top: $header-height;

  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
  @media (max-width: 900px) {
    margin-left: 0px;
    margin-right: 0px;
    border: none;
  }
  @media (max-width: 768px) {
    padding-top: $mobile-header-height;
  }
  .category-list {
    display: block;
  }
  .page-content {
    //flex: 1;
    width: calc(100% - 320px);
    min-height: 500px;
    padding-left: 20px;
    @media (max-width: 930px) {
      width: 100%;
      padding-left: 0px;
    }
    > ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      align-items: stretch;
      justify-content: center;
      column-gap: 25px;
      row-gap: 20px;

      // background-color: ;
      @media (max-width: 768px) {
        // justify-content: flex-start;
        width: 100vw;
        flex-direction: row;
        justify-content: center;
      }
      > li {
        // width: calc(100%/6 - 20px + 20px/6);
        // margin-left: 1%;
        margin-bottom: 20px;
        position: relative;
        @media (max-width: 768px) {
          margin-left: 0px;
          min-width: 140px;
        }
        .sale-icon {
          position: absolute;
          right: -0px;
          top: 0px;
          max-width: 60px;
          z-index: 100;
        }
      }
    }
  }
  .page-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 40px;
    a {
      padding: 5px;
      color: $graySilver;

      font-size: 17px;

      height: 39px;
      width: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (hover) {
        transition: color 0.4s, background-color 0.4s;
        &:hover {
          color: $orangeHeavy;
        }
      }
    }
    .number-page {
      overflow: hidden;
      max-width: 110px;
      max-height: 40px;
      .pagination {
        overflow-x: scroll;
      }
    }
    .active-page {
      color: $orangeHeavy;
      border-bottom: 2px solid $orangeHeavy;
    }
  }
  .pageNumber-wraper {
    // background-color: $orangeHeavy;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .select-page-number {
      color: $orangeHeavy;
      background-color: white;
      margin: 10px;
      width: 70px;
      height: 35px;
      border-radius: 5px;
      text-align: right;
      padding: 20 0;
      font-size: 17px;
      border: 1px solid $orangeHeavy;
      cursor: pointer;
    }
    @media (max-width: 800px) {
      display: none;
    }
  }
  .pagination {
    display: flex;
    li {
      margin-left: 2px;
    }
  }
  .page-button {
    margin-right: 5px;
    margin-left: 5px;
    width: auto !important;
    min-width: 34px;
  }

  .add-to-cart {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 50px;
    span {
      background-color: $orangeHeavy;
      padding: 10px;
      color: white;
      cursor: pointer;
    }
  }
}
