@use "./variable" as *;
.cart-page {
  border: 1px solid $sand;
  // margin: 1%;
  padding-top: $header-height;
  display: flex;
  // margin-top: $header-height;
  @media (max-width: 900px) {
    border: none;
  }
  @media (max-width: 768px) {
    padding-top: $mobile-header-height;
  }
  .cart-content {
    display: flex;
    // flex: 1;
    padding-top: 20px;
    justify-content: flex-end;
    width: calc(100% - 301px);

    // padding-left: 20px;
    // padding: 20px;
    @media (max-width: 1150px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }
    .items-cart {
      flex: 0.7;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      @media (max-width: 1150px) {
        flex: none;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }
      @media (max-width: 768px) {
        padding-left: 5px;
        padding-right: 5px;
      }
      > h2 {
        text-transform: capitalize;
        color: $blueSea;
        padding-left: 10px;
        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
      .product-cart-section:first-child {
        margin-top: 20px;
        @media (max-width: 800px) {
          margin-top: 15px;
        }
      }
      .product-cart-section {
        // border: 1px solid $sand;
        display: flex;
        align-items: center;
        // margin-bottom: 25px;
        min-height: 117px;
        width: 100%;
        // margin-left: 1%;
        // margin-right: 1%;
        justify-content: space-between;
        @media (max-width: 400px) {
          // flex-direction: column;
        }
        .cart-img-wraper {
          width: 120px;
          margin: 10px;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 400px) {
            width: 100px;
            // height: ;
          }
        }
        .product-information {
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: space-around;
          column-gap: 20px;
          @media (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 10px;
            > div {
              width: 100%;
              margin-bottom: 10px;
            }
          }
          .product-description {
            width: 150px;
            color: $blueSea;

            @media (max-width: 1030px) {
              width: 120px;
            }
            @media (max-width: 1030px) {
              width: 100%;
            }
            @media (max-width: 600px) {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 14px;
            }
            abbr {
              text-decoration: none;
              max-width: 150px;
              overflow: hidden;
            }
            a {
              color: black;
            }
            svg {
              display: none;
              @media (max-width: 600px) {
                display: block;
                color: #ff000080;
              }
            }
          }
          .product-totalPrice {
            width: 100px;
            @media (max-width: 600px) {
              display: flex;
              justify-content: flex-end;
              width: 100%;
              // padding-right: 20px;
            }
          }
          .item_quantity {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 600px) {
              justify-content: flex-end;
              // padding-right: 20px;
            }

            // input[type="number"] {
            //   -moz-appearance: textfield;
            // }
          }
        }
        .deleteIcon {
          padding: 10px;
          color: #ff000080;
          @media (max-width: 600px) {
            display: none;
          }
        }
      }
    }
    .subTotal-gap {
      flex: 0.3 1;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 20px;
      // min-width: 250px;
      max-width: 400px;
      padding: 10px;
      padding-top: 0px;
      width: 100%;
      @media (max-width: 1100px) {
        margin-top: 0px;
        // width: 100%;
      }
      .subTotal {
        // border: 1px solid $sand;
        min-width: 300px;
        width: 100%;
        // margin: 4%;
        min-height: 270px;
        padding: 20px;
        h4 {
          color: $blueSea;
          margin-bottom: 18px;
        }
        .subTotal_details {
          row-gap: 10px;
          display: flex;
          flex-direction: column;
          .total-parts,
          .shipping-cost,
          .total-cost {
            color: $graySilver;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
          }
          .fieldset_shippment {
            display: flex;
            justify-content: space-around;
            padding: 10px;
            margin-bottom: 10px;
            border: 1px solid $liteBlue;
            input {
              margin: 5px;
            }
          }
          .schedualed {
            // height: 30px;

            > label {
              text-transform: capitalize;
              > input {
                margin-right: 5px;
              }
            }
          }
          .total-cost {
            margin-bottom: 20px;
            color: black;
          }
          .subTotal_Buy,
          .subTotal_Buy-enabled {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
              text-transform: uppercase;
              width: 130px;
              height: 36px;
              background-color: $graySilver;
              color: white;
              outline: none;
              border: none;
            }
            .enabled_btn {
              background-color: $orangeHeavy;
              color: white;
              cursor: pointer;
              &:hover {
                background-color: $sand;
              }
            }
          }
        }
      }
    }
  }

  .checkout-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000000a8;
    top: 0;
    left: 0;
    z-index: 102;
    display: flex;
    justify-content: center;
    align-items: center;

    .popup-content {
      background-color: white;
      width: 500px;
      height: 500px;
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      border-radius: 10px;
      .popup-top {
        h1 {
          font-size: 30px;
          font-family: "Poppins", sans-serif;
          color: $blueSea;
          text-transform: capitalize;
          @media (max-width: 768px) {
            font-size: 28px;
          }
          > svg {
            color: $orangeHeavy;
          }
        }
        .closepopup {
          display: grid;
          position: absolute;
          top: 40px;
          right: 40px;
          cursor: pointer;

          svg {
            font-size: 30px;
            color: $blueSea;
            @media (hover) {
              transition: color 0.4s;
              &:hover {
                color: $orangeHeavy;
              }
            }
          }
        }
      }
      > fieldset {
        width: 100%;
        padding-bottom: 20px;
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0;
        // border: 1px solid $sand;
        // padding-bottom: 20px;
      }

      li {
        // width: calc(50% - 20px + 10px);
        width: 100%;
        margin-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 14px;
        }
        small {
          font-size: 13px;
          color: $orangeHeavy;
          text-transform: uppercase;
        }

        .error {
          color: red;
          position: absolute;
          position: absolute;
          right: 10px;
          top: 10px;
        }
        input {
          width: 100%;
          padding-left: 10px;
          padding-right: 10px;
          height: 40px;
          border: none;
          border-radius: 5px;
        }
      }
      .li-inputs-wraper {
        border: 1px solid $graySilver;
        padding: 5px 10px;
        border-radius: 5px;
      }
      .li-note-wraper {
        border: 1px solid $graySilver;
        padding: 5px 10px;
        border-radius: 5px;
        > svg {
          color: $graySilver;
          position: absolute;
          top: 10px;
          left: 10px;
        }
        > textarea {
          width: 100%;
          padding: 5px 30px;
          min-height: 100px;

          border: none;
          border-radius: 5px;
        }
      }
      button {
        height: 35px;
        width: 130px;
        // border-radius: 10px;
        text-transform: uppercase;
        outline: none;
        border: none;
        color: white;
        background-color: $graySilver;
        font-weight: 700;
        margin-top: 10px;
      }
      .active-confirm-order {
        cursor: pointer;
        background-color: $orangeHeavy;
        @media (hover) {
          transition: color 0.4s;
          &:hover {
            background-color: $sand;
          }
        }
      }
      @media (max-width: 600px) {
        .popup-content {
          width: 100%;
          height: 100%;
          padding-top: 100px;
          justify-content: flex-start;
          overflow-y: scroll;
        }
        .popup-content li {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}
