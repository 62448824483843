@use "./variable" as *;
.stock-page {
  // padding-top: $header-height;
  padding-top: $header-height;
  @media (max-width: 768px) {
    padding-top: $mobile-header-height;
  }
  .top-page {
    .import-scv {
      color: $blueSea;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
  .th-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    // justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    > label {
      font-size: 14px;
      text-transform: capitalize;
    }
    .search-input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      > input {
        width: 100%;
        height: 20px;
        padding-left: 10px;
        border: none;
        outline: none;
        // border: 1px solid $silverMid;
        // border-radius: 5px;
      }
      > button {
        width: 20px;
        height: 20px;
        border: none;
        // background-color: ;
        display: flex;
        justify-content: center;
        align-items: center;
        > svg {
          color: $orangeHeavy;
        }
      }
    }
  }
  .th-picture {
    text-align: center;
    > select {
      width: 50%;
      border: none;
      height: 30px;
      background-color: white;
      color: $blueSea;
    }
  }
  .td-picture {
    .td-cell {
      position: relative;
    }
  }
  .clear-filter-wraper {
    > img {
      width: 25px;

      fill: $blueSea;
    }
  }
  .dialog-popup {
    .popup-content {
      width: 80%;
      height: 80%;
      padding: 10px;
      @media (max-width: 1000px) {
        width: 90%;
        height: 95%;
      }
      > .form-wraper {
        // background-color: $blueSea;
        overflow-y: auto;
        // max-height: 500px;
        width: 100%;

        .img-section {
          padding: 10px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: center;

          .picture-wrapper {
            // border: 1px solid $graySilver;
            width: 100%;
            width: 350px;
            // height: 350px;
            max-height: 300px;
            margin-bottom: 10px;
            > img {
              width: 100%;
              height: 100%;
              object-fit: fill;
              object-position: center;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              @media (max-width: 768px) {
                max-width: 250px !important;
                max-height: 220px !important;
              }
            }
          }
          > button {
            width: 50px;
          }
        }
        > form {
          width: 100%;
          display: flex;

          > ul {
            display: flex;
            width: 100%;
            flex-direction: column;
            > li {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
              padding-left: 10px;
              padding-right: 10px;
              flex-direction: column;
              label {
                color: $graySilver;
                font-weight: 600;
                font-size: 12px;
              }
              input {
                border: 1px solid $graySilver;
                color: $blueSea;
                height: 25px;
                padding-left: 5px;
              }
              > select {
                height: 30px;
                color: $blueSea;
              }
            }
          }
          @media (max-width: 768px) {
            flex-direction: column;
            max-height: 100%;
          }
        }
      }
      .controls-section {
        text-align: center;
        padding-top: 10px;
        // position: absolute;
        button {
          width: 150px;
          text-align: center;
          border-radius: 10px;
          border: none;
          height: 30px;
          color: $graySilver;
          text-transform: capitalize;
          font-size: 14px;
        }
        .active-btn {
          cursor: pointer;
          color: white;
          background-color: $orangeHeavy;
          &:hover {
            background-color: $sand;
          }
        }
      }
    }
  }
}
