@use "./variable" as *;

.signIn-page {
  height: calc(100vh - #{$header-height});
  width: 100%;
  position: relative;
  min-height: 630px;
  padding-top: $header-height;
  @media (max-width: 670px) {
    min-height: 500px;
  }
  .content {
    height: 100%;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    .form-wrapper {
      border: 1px solid #f25c11;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      position: relative;
      @media (max-width: 670px) {
        border: none;
      }
      .left-content,
      .right-content {
        width: 50%;
        height: 100%;

        @media (max-width: 670px) {
          display: none;
        }
      }

      form {
        padding: 20px;
        z-index: 126;
        position: absolute;
        background: white;
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0px 3px 6px #000000;
        margin-top: 30px;
        @media (max-width: 670px) {
          width: 100%;
          margin-top: 0px;
          padding: 10px;
        }
        .form-content {
          border: 1px solid $orangeHeavy;
          padding: 20px;
          border-radius: 20px;
          row-gap: 20px;
          .reset-password-title {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 23px;
            color: $blueSea;
            @media (max-width: 800px) {
              font-size: 18px;
            }
          }
          .form-header {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-bottom: 20px;
            width: 100%;
            @media (max-width: 800px) {
              // margin-bottom: 10px;
            }

            h1 {
              font-size: 23px;
              color: $blueSea;
              margin-bottom: 10px;
              cursor: pointer;
              padding-bottom: 10px;
              @media (max-width: 700px) {
                font-size: 18px;
              }
            }
            .h1-active {
              border-bottom: 2px solid $sand;
            }
          }

          @media (max-width: 600px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          ul {
            height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            min-height: 350px;
            @media (max-width: 600px) {
              width: 92%;
            }

            li {
              width: 100%;
              &:not(:first-child) {
                margin-top: 15px;
              }
              input {
                width: 100%;
                padding: 15px 10px;
                background-color: white;
                outline: none;
                border: 1px solid $sand;
              }
              .password-wraper {
                display: flex;
                :first-child {
                  margin-right: 5px;
                }
              }
              .resetPassword {
                color: $blueSea;
                font-size: 13px;
              }
            }
            .back-signIn-page {
              color: $blueSea;
              font-size: 13px;
              a {
                color: #f25c11;
                text-decoration: underline;
              }
            }
            .upload-file {
              color: $blueSea;
              font-size: 13px;
              cursor: pointer;
            }
          }
          button {
            background-color: $orangeHeavy;
            outline: none;
            width: 500px;
            padding: 15px 10px;
            color: white;
            border: none;
            margin-top: 20px;
            font-size: 20px;
            display: flex;
            justify-content: center;
            cursor: pointer;
            @media (max-width: 600px) {
              width: 92%;
            }
            @media (hover) {
              transition: color 0.5s, background-color 0.5s;
              &:hover {
                color: $orangeHeavy;
                background-color: white;
              }
            }
          }
        }
      }
    }
  }
}
